.phone-app {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    flex-flow: row wrap;
    align-content: space-between;
    color: $white;
    @include fontSize(14);
    background: transparent;
    border: none;
    cursor: pointer;

    .app-title {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .app-icon {
        width: 100%;
    }
}