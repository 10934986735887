*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    font-family: $mainFont;
}

.App {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    .game-enter {
        opacity: 0;
    }
    .game-exit {
        opacity: 1;
    }
    .game-enter-active {
        opacity: 1;
    }
    .game-exit-active {
        opacity: 0;
    }
    .game-enter-active,
    .game-exit-active {
        transition: all 700ms ease-in-out;
    }
}