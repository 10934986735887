@font-face {
    font-family: 'Courier';
    src: url('../../assets/fonts/courier/CourierPrime-Regular.eot');
    src: url('../../assets/fonts/courier/CourierPrime-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/courier/CourierPrime-Regular.woff2') format('woff2'),
        url('../../assets/fonts/courier/CourierPrime-Regular.woff') format('woff'),
        url('../../assets/fonts/courier/CourierPrime-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Courier';
    src: url('../../assets/fonts/courier/CourierPrime-Bold.eot');
    src: url('../../assets/fonts/courier/CourierPrime-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/courier/CourierPrime-Bold.woff2') format('woff2'),
        url('../../assets/fonts/courier/CourierPrime-Bold.woff') format('woff'),
        url('../../assets/fonts/courier/CourierPrime-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}