.phone--web {
    position: relative;
    width: 100%;
    height: 100%;
    background: $gray2C;
    color: $white;

    .web--navigator {
        width: 100%;
        height: 83%;
        padding: 0 10px;

        .navigator--search-bar {
            position: relative;
            width: 100%;
            padding: 0 0 10px;

            label {
                display: inline-block;
                margin: 0 0 5px;
                font-weight: bold;
            }

            input {
                &[type="text"] {
                    width: 100%;
                    background: $gray22;
                    padding: 10px;
                    color: $white;
                    @include fontSize(15);
                    border: 1px solid $white;
                    border-radius: 5px;
                }

                &[type="submit"] {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    color: transparent;
                    border: none;
                    cursor: pointer;
                    overflow: hidden;
                    z-index: 2;
                }
            }

            .search-bar--submit {
                position: absolute;
                bottom: 11px;
                right: 0;
                height: 37px;
                width: 37px;
                background: $gray2C;
                border: 1px $white solid;
                border-radius: 5px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 8px;
                }
            }
        }

        .navigator--result {
            height: 84%;
            padding: 0 0 10px;
            overflow-y: scroll;
            .result--title {
                padding: 0 15px;
                @include fontSize(18);
            }

            .result--content {
                padding: 0 15px;
                @include fontSize(15);
                line-height: 1.5rem;
                white-space: pre-wrap;
            }
        }
    }
}
