.btn {
    display: inline-block;
    margin: 0;
    padding: 5px 20px;
    text-align: center;
    @include fontSize(15);
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &.btn--red {
        color: $white;
        @include gradient($red, $darkRed);
    }

    &.btn--green {
        color: $white;
        @include gradient($green, $darkGreen);
    }
}
