.phone-app-button {
    display: flex;
    width: 67px;
    height: 67px;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: $gray3B;
    border: solid $gray70 2px;
    border-radius: 67px;
    overflow: hidden;
    cursor: pointer;

    .phone-app-button--content {
        display: flex;
        width: 100%;
        max-height: 100%;
        padding: 0 17px;
        flex-flow: row wrap;
        justify-content: center;
        color: $white;

        .phone-app-button--title {
            margin: 0;
            padding: 0;
            @include fontSize(30);
        }

        .phone-app-button--small {
            margin: 0;
            padding: 0;
            @include fontSize(10);
        }

        .phone-app-button--icon {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
        }
    }

    &.phone-app-button--green {
        border: none;
        @include gradient($green, $darkGreen);
    }

    &.phone-app-button--red {
        border: none;
        @include gradient($red, $darkRed);
    }

    &.phone-app-button--purple {
        border: none;
        @include gradient($purple, $darkPurple);
    }
}