.phone--call-screen {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    .phone-call--head {
        display: flex;
        height: 35px;
        margin: 0 0 15px;
        flex-flow: row wrap;
        justify-content: space-between;
        color: $white;

        .phone-call--number {
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: center;
            @include fontSize(30);
        }

        .phone-call--details {
            width: 100%;
            margin: 11px 0 0;
            padding: 0;
            text-align: center;
            @include fontSize(15);
        }
    }

    .phone-app-button--red {
        margin: 0 auto;
    }
}