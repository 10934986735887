.page.page--game-board {
    width: 100vw;
    height: 100vh;

    .container {
        height: 100%;
    }

    .row {
        height: 100%;
    }

    [class ^= 'col'] {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
}