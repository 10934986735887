.phone--main-screen {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 80px 20px 0px;
    background-image: url('../../../assets/images/background-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-template-columns: repeat(3, 71px);
    grid-template-rows: repeat(2, 93px);
    column-gap: 24px;
    row-gap: 26px;
    z-index: 0;
}