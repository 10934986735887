.phone--mail {
    position: relative;
    width: 100%;
    height: 100%;
    background: $gray2C;

    .mail--index,
    .mail--reader {
        padding: 0 15px 90px;
        height: calc(100% - 90px);
        overflow-y: scroll;
    }

    .mail--reader {
        color: $white;

        .sender {
            margin: 0 0 0 21px;
            @include fontSize(15);
        }

        .content {
            margin: 18px 0 0 0;
            white-space: pre-line;
            word-break: break-word;
        }
    }

    .mail--write {
        position: absolute;
        bottom: 30px;
        right: 30px;
        width: 50px;
        height: 50px;
        padding: 13px;
        @include gradient($orange, $orangeDark);
        border: none;
        border-radius: 50px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
        }
    }

    ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
    }

    ::-webkit-scrollbar-thumb {
        background: $white;
    }
}