.mail-item {
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 10px;
    @include gradient($orange, $orangeDark);
    border: none;
    border-radius: 15px;
    cursor: pointer;

    .mail-item--title {
        width: 100%;
        margin: 0;
        @include fontSize(10);
        font-weight: bold;
        color: $white;
        text-align: start;
    }

    .mail-item--subject {
        width: 100%;
        margin: 8px 0 0;
        @include fontSize(10);
        font-weight: normal;
        color: $white;
        text-align: start;
    }

    .mail-item--time {
        position: absolute;
        top: 0px;
        right: 38px;
        @include fontSize(10);
        font-weight: normal;
        color: $white;
    }

    &+.mail-item {
        margin-top: 20px;
    }
}