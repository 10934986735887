.phone--davinci {
    position: relative;
    width: 100%;
    height: 100%;
    background: $gray2C;

    .davinci--main {
        padding: 0 10px;

        textarea,
        .davinci--result {
            width: 100%;
            height: 115px;
            padding: 6px 10px;
            background: $gray22;
            color: $white;
            @include fontSize(15);
            border: 1px solid $white;
            border-radius: 10px;
            resize: none;
            white-space: pre-line;
            word-break: break-word;
            overflow-y: scroll;
        }

        .davinci--icon {
            width: 100%;
            height: 20px;
            margin: 15px 0;
            object-position: center;
            object-fit: contain;
        }

        .davinci--title {
            margin: 0 0 6px;
            color: $white;
            @include fontSize(16);
            font-weight: normal;
        }
    }
}