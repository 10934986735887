.phone--report {
    position: relative;
    width: 100%;
    height: 100%;
    background: $gray2C;
    color: $white;

    .main {
        width: 100%;
        padding: 0 10px;
    }

    .report--form {
        .report--form-line {
            width: 100%;

            label {
                display: block;
                width: 100%;
                margin-bottom: 5px;
                @include fontSize(16);
            }

            select {
                width: 100%;
                padding: 10px;
                background: $gray22;
                color: $white;
                @include fontSize(15);
                border: none;
                border-radius: 5px;
            }

            &+.report--form-line {
                margin-top: 10px;
            }
        }

        form {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        input[type='submit'] {
            margin: 25px 0 0;
        }
    }

    .report--wrong,
    .report--win {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: center;

        .report--status {
            width: 50px;
        }

        .report--title {
            width: 100%;
            margin: 25px 0;
            font-weight: bold;
            @include fontSize(20);
            text-align: center;
        }

        .report--text {
            width: 100%;
            margin: 0 0 25px;
            @include fontSize(15);
            text-align: center;
        }
    }
}