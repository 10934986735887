.app-header {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 42px 13px 0;
    flex-flow: row nowrap;
    align-items: center;

    .app-header--action {
        width: 10px;
        height: 20px;
        margin-right: 12px;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
        }
    }

    .app-header--title {
        margin: 0;
        padding: 0;
        color: $white;
        @include fontSize(25);
        font-weight: bold;
    }

}