@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/Roboto-Black.eot');
    src: url('../../assets/fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/roboto/Roboto-Black.woff2') format('woff2'),
        url('../../assets/fonts/roboto/Roboto-Black.woff') format('woff'),
        url('../../assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/roboto/Roboto-Regular.eot');
    src: url('../../assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
        url('../../assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
        url('../../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}