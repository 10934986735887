$black: #000000;
$white: #FFFFFF;
$gray: #6C6C6C;
$darkGray: #363636;
$gray22: #222222;
$gray2C: #2C2C2C;
$gray3B: #3B3B3B;
$gray70: #707070;
$yellow: #F5E28F;
$green: #41C136;
$darkGreen: #097100;
$red: #FF0000;
$darkRed: #B90000;
$purple: #A700ff;
$darkPurple: #730DA8;
$orange: #FFBA00;
$orangeDark: #D3700C;