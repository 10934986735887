.note-pad {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
    padding: 35px 20px 10px;
    flex-flow: column nowrap;
    border-radius: 10px;
    background: $yellow;
    box-shadow: rgba($black, .47) 0 10px 34px;
    font-family: $courierFont;

    .note-pad--title {
        width: 100%;
        margin: 0 0 20px 0;
        padding: 0;
        @include fontSize(70);
        font-weight: bold;
        flex-shrink: 0;
    }

    .note-pad--text {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        @include fontSize(30);
        resize: none;
    }
}