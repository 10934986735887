.phone--keypad {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 45px 25px;
    background: $gray2C;

    .phone-keypad--head {
        display: flex;
        height: 35px;
        margin: 0 0 15px;
        flex-flow: row nowrap;
        justify-content: space-between;
        color: $white;

        .phone-keypad--number {
            margin: 0;
            padding: 0;
            @include fontSize(30);
        }

        button {
            @include fontSize(15);
            border: none;
            background: transparent;
            text-align: end;
            color: $white;
            cursor: pointer;
        }
    }

    .phone-keypad--grid {
        display: grid;
        width: 100%;
        margin: 0 0 16px;
        grid-template-columns: repeat(3, 67px);
        grid-template-rows: repeat(4, 67px);
        column-gap: 26px;
        row-gap: 16px;
    }

    .phone-app-button--green {
        margin: 0 auto;
    }
}