.phone {
    width: 315px;
    height: 640px;
    background: gray;
    border-radius: 30px;
    box-shadow: rgba($black, .25) 0 3px 20px;

    .phone--frame {
        position: relative;
        width: 100%;
        height: 57px;

        .phone-frame--line {
            position: absolute;
            top: calc( 50% - 4px );
            left: calc( 50% - (96px / 2) );
            width: 96px;
            height: 8px;
            background: $darkGray;
            border-radius: 10px;
        }

        .phone-frame--camera {
            position: absolute;
            top: calc(50% - (17px / 2));
            left: 220px;
            width: 17px;
            height: 17px;
            background: $darkGray;
            border-radius: 17px;
        }

        button {
            color: transparent;
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }

    .phone--screen {
        position: relative;
        margin: 0 6px;
        height: calc(100% - (2 * 57px));
        overflow: hidden;

        .phone-screen--header {
            position: absolute;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            top: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            color: $white;
            z-index: 99;

            p {
                display: inline;
                margin: 0;
                padding: 0;
                @include fontSize(14);
                font-weight: bold;
            }

            .phone--carrier {
                position: relative;
                padding-left: calc(6px + 15px);

                &::before {
                    content: '\0020';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 15px;
                    height: 14px;
                    background-image: url('../../assets/images/network.png');
                    background-position: center;
                    background-size: contain;
                }
            }
        }

        .phone--transition {
            height: 100%;
        }

        .app-enter {
            transform: translateY(100%);
        }
        .app-exit {
            transform: translateY(0);
        }
        .app-enter-active {
            transform: translateY(0);
        }
        .app-exit-active {
            transform: translateY(100%);
        }
        .app-enter-active,
        .app-exit-active {
            transition: all 250ms ease-in-out;
        }
    }
}