.phone--notification {
    position: absolute;
    top: 35px;
    left: calc(10% / 2);
    width: 90%;
    z-index: 999;

    .notification--body {
        padding: 10px;
        background: $gray2C;
        border-radius: 5px;
        box-shadow: 0 3px 10px $gray22;
    }

    .notification--row {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        align-items: center;
    }

    .notification--icon {
        width: 25px;
        height: 25px;
    }

    .notification--title {
        width: 100%;
        margin: 0 0 10px;
        @include fontSize(14);
        color: $white;
        text-align: start;
    }

    .notification--text {
        margin: 0;
        @include fontSize(12);
        color: $white;
    }
}

.notification-anim-enter {
    transform: translateY(-100px);
}
.notification-anim-exit {
    transform: translateY(0);
}
.notification-anim-enter-active {
    transform: translateY(0);
}
.notification-anim-exit-active {
    transform: translateY(-100px);
}
.notification-anim-enter-active,
.notification-anim-exit-active {
    transition: all 250ms ease-in-out;
}
