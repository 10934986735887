.phone--music-player {
    position: relative;
    width: 100%;
    height: 100%;
    background: $gray2C;

    .music-player--main {
        padding: 0 30px 0;
    }

    .music-player--current {

        color: $white;

        .music-thumbnail {
            width: 243px;
            height: 243px;
            object-position: center;
            object-fit: cover;
        }
        .music-title {
            width: 100%;
            margin: 13px 0;
            text-align: center;
            @include fontSize(20);
            font-weight: bold;
        }
        .music-artist {
            width: 100%;
            margin: 0;
            text-align: center;
            @include fontSize(15);
        }
    }

    .music-player--controller {
        display: flex;
        width: 100%;
        margin-top: 20px;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .phone-app-button {
            width: 60px;
            height: 60px;
            margin: 0 20px 0;
        }

        .controller-icon {
            width: 30px;
        }
    }
}