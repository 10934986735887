.game-start {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;

    .game--title {
        width: 100%;
        text-align: center;
        img {
            max-width: 300px;
        }
    }

    .game--level {
        width: 100%;
        text-align: center;
        @include fontSize(25);

        @media (max-width: $md) {
            display: none;
        }
    }

    .btn {
        padding: 20px;
        @include fontSize(20);

        @media (max-width: $md) {
            display: none;
        }
    }

    .game--screen-notice {
        width: 100%;
        text-align: center;

        @media (min-width: $md) {
            display: none;
        }
    }
}